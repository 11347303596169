import React, { useEffect } from 'react';
import ReactFullpage from '@fullpage/react-fullpage';
// import { navigate } from '@reach/router';
import { graphql, useStaticQuery } from 'gatsby';

import Layout from '../components/Layout';

import PotockLink from '../components/PotockLink';

import RotateDevice from '../components/RotateDevice';

// import LocaleContext from '../context/Locale';
import BackgroundImage from 'gatsby-background-image';
import styled from 'styled-components';
// import FullScreenContainer from '../components/FullScreenContainer';

// FullPage lib
// import fullpage from 'fullpage.js';
import 'fullpage.js/dist/fullpage.css';

// Animations
import SplitText from 'gsap/SplitText';
import gsap from 'gsap';

// Styled components
const ArtDirectedBackground = ({ className, children, coverImage }) => {
  // const { mobileImage, desktopImage } = useStaticQuery(

  const sources = [coverImage.childImageSharp.fluid];

  return (
    <BackgroundImage
      Tag="div"
      id="cover-image"
      className={className + ' full-screen w-full'}
      style={{
        paddingTop: '4rem',
      }}
      fluid={sources}
    >
      {children}
    </BackgroundImage>
  );
};

const SectionWithCoverBackground = styled(ArtDirectedBackground)`
  width: 100%;
  background-position: bottom center;
  background-repeat: repeat-y;
  background-size: 100%;
`;

// Main Component
function IndexPage() {
  // Get all cover images
  const { allFile: images } = useStaticQuery(
    graphql`
      query {
        allFile(filter: { absolutePath: { regex: "/history/" } }) {
          edges {
            node {
              name
              dir
              absolutePath
              base
              childImageSharp {
                fluid(quality: 95, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    `
  );

  // Setup initial state of animations
  useEffect(() => {
    const initialAnim = gsap.timeline();

    initialAnim.set('.places > div', { opacity: 0 });
    initialAnim.set('.section[data-anchor="description"] .btn-cta', {
      opacity: 0,
    });
    // initialAnim.set('.content-wrapper h2, .content-wrapper h3, .content-wrapper .btn-cta', { opacity: 0 });
  }, []);

  // Animate navigation
  useEffect(() => {
    gsap.from('#fp-nav', {
      opacity: 0,
      x: 10,
      duration: 3,
      delay: 3,
      ease: 'power4.out',
    });
  }, []);

  // Init tour anim
  let tourAnim = gsap.timeline({ repeat: -1, paused: true });

  // Animate first screen
  const animateFirstScreen = screen => {
    const splitText = new SplitText('.section .anim-description p', {
      type: 'lines',
    });
    const anim = gsap.timeline({ delay: 0.5 });

    anim.from(splitText.lines, {
      opacity: 0,
      y: '50%',
      duration: 1,
      stagger: 0.1,
      duration: 1.4,
      ease: 'power4.out',
    });

    anim.fromTo(
      '.section[data-anchor="description"] .btn-cta',
      { opacity: 0 },
      { duration: 3, ease: 'power4.out', opacity: 1, y: 0 },
      '+=2'
    );
  };

  // First screen animation - run once on start
  useEffect(() => {
    animateFirstScreen();
  }, []);

  // Prepare content and styling
  const coverImages = images.edges.map(i => i.node);

  const getCoverImageFluid = coverImageName => {
    return coverImages.find(c => coverImageName === c.name).childImageSharp
      .fluid;
  };

  const tourPlaces = [
    'Bukowina Tatrzańska',
    'Zgorzelec',
    'Nowy Sącz',
    'Wygiełzów',
    'Sucha Beskidzka',
    'Maków Podhalański',
    'Zawoja',
    'Stryszawa',
    'Tokarnia',
    'Lipnica',
  ];

  const mdScreen =
    typeof window !== 'undefined' ? window.innerWidth > 768 : true;

  return (
    <Layout fullPage={true} shop={true}>
      <ReactFullpage
        //fullpage options
        licenseKey={'B340292B-5CE84E7D-A2DC0C48-C28F1CB9'}
        scrollingSpeed={1000} /* Options here */
        autoScrolling={true}
        navigation={true}
        navigationPosition="right"
        showActiveTooltip={mdScreen}
        onLeave={(origin, destination, direction) => {
          const sectionAnim = gsap.timeline();

          const anchor = destination.anchor;
          const basicFrom = { opacity: 0, y: 10 };
          // const basicTo = { stagger: 0.1, duration: 1.4, y: 0, opacity: 1, delay: 0.5 };
          const basicTo = {
            delay: 0.5,
            duration: 1.5,
            opacity: 1,
            y: 0,
            ease: 'power4.out',
          };

          // Animate background image from scaled to normal on enter
          if (destination !== 'description') {
            sectionAnim.to('.section[data-anchor="' + anchor + '"]', {
              scale: 1.1,
              duration: 0.1,
            });
            sectionAnim.to('.section[data-anchor="' + anchor + '"]', {
              scale: 1,
              duration: 0.8,
            });
          }

          // Animate elements on origin
          const contentWrapperClass =
            '.section[data-anchor="' + origin.anchor + '"] .content-wrapper';
          const contentElements = [
            contentWrapperClass + ' h2',
            contentWrapperClass + ' h3',
            contentWrapperClass + ' a.btn-cta',
          ];

          const ct = gsap.timeline();
          const transition = 'all 0.3s';

          // First turn of the transition of btn-cta
          ct.set(contentWrapperClass + ' a.btn-cta', { transition: 'none' });
          if (direction === 'down') {
            ct.to(contentElements, {
              stagger: 0.1,
              duration: 0.5,
              y: -20,
              opacity: 0,
              ease: 'power3.out',
            });
          } else {
            ct.to(contentElements, {
              stagger: {
                from: 'end',
                each: 0.1,
              },
              duration: 0.5,
              y: 20,
              opacity: 0,
              ease: 'power3.out',
            });
          }
          // Turn on the transition
          ct.set(contentWrapperClass + ' a.btn-cta', {
            transition: transition,
          });

          // sectionAnim.fromTo('.content-wrapper h2, .content-wrapper h3, .content-wrapper .btn-cta', basicFrom, basicTo);
          sectionAnim.fromTo(
            '.section[data-anchor="' + anchor + '"] .content-wrapper h2',
            basicFrom,
            basicTo
          );
          sectionAnim.fromTo(
            '.section[data-anchor="' + anchor + '"] .content-wrapper h3',
            basicFrom,
            basicTo,
            '-=1.6'
          );
          sectionAnim.fromTo(
            '.section[data-anchor="' + anchor + '"] .content-wrapper .btn-cta',
            basicFrom,
            basicTo,
            '-=1.6'
          );

          if (
            destination.anchor === 'first_tour' &&
            tourAnim &&
            tourAnim.paused()
          ) {
            const tourSplitText = new SplitText('.places div', {
              type: 'words,chars,lines',
            });
            tourAnim.resume();

            tourAnim.set('.places > div', { opacity: 1 });
            tourAnim.set('.places', { perspective: 400 });

            const stagger = 1.5;
            const staggerDuration = 1;

            // Fade in from right
            tourAnim.from(tourSplitText.lines, {
              opacity: 0,
              x: '20%',
              duration: staggerDuration,
              stagger: stagger,
              ease: 'power4.out',
            });
            // Fade out
            tourAnim.to(
              tourSplitText.lines,
              { opacity: 0, duration: 0.3, stagger: stagger },
              1
            );
          } else if (destination.anchor === 'description') {
            animateFirstScreen();
          }
        }}
        render={({ state, fullpageApi }) => {
          return (
            <div id="fullpage" className="band-history">
              <ReactFullpage.Wrapper className="band-history">
                <div
                  className="section"
                  data-anchor="description"
                  data-tooltip="O zespole"
                >
                  <div className="pt-20 px-6 full-page h-screen flex flex-col anim-description">
                    <p
                      className="mb-6 md:mb-20 text-white mx-auto text-sm md:text-xl w-full"
                      style={{ maxWidth: '500px' }}
                    >
                      PotOCK - porywające zderzenie muzyki rockowej z ludowymi
                      tekstami, połączenie nieoczywistego brzmienia i
                      innowacyjnych aranżacji tradycyjnych melodii karpackich.
                      Zespół swobodnie i spontanicznie, bawiąc się dźwiękiem i
                      łącząc style porywa słuchaczy w podróż po polskich,
                      rumuńskich, węgierskich oraz słowackich halach i polanach.
                    </p>
                    <p
                      className="mb-6 md:mb-20 text-white mx-auto text-sm md:text-xl w-full"
                      style={{ maxWidth: '500px' }}
                    >
                      Niesamowity skład spod Babiej Góry tworzy folkowo – rockową ucztę muzyczną, która na długo pozostanie w Waszej pamięci. Cztery góralskie kobiece wokale w połączeniu z mocnym brzmieniem gitar, basu i perkusji to coś, czemu nie można się oprzeć.

                    </p>
                    <div className="mx-auto text-center">
                      <a
                        href="#theater"
                        className="btn btn-cta mx-auto opacity-0"
                      >
                        Poznaj naszą historię
                      </a>
                    </div>
                  </div>

                  <RotateDevice />
                </div>

                <div
                  className="section text-white "
                  data-anchor="theater"
                  data-tooltip="widowisko teatralne"
                >
                  <BackgroundImage
                    Tag="div"
                    className="full-page h-screen"
                    fluid={getCoverImageFluid('theater')}
                  >
                    <div className="content-wrapper absolute inset-y-auto left-0 mr-6 pl-6 flex flex-col h-screen justify-center text-white">
                      <div>
                        <h2>
                          “Pamiętnik Babiej Góry”
                          <br />
                          widowisko teatralne
                        </h2>
                        <h3 className="date">lipiec 2018</h3>
                        {/* <a href="#" className="btn btn-cta">Zobacz przygotowania</a> */}
                      </div>
                    </div>
                  </BackgroundImage>
                </div>

                <div
                  className="section text-white"
                  data-anchor="first_show"
                  data-tooltip="pierwszy koncert"
                >
                  <BackgroundImage
                    Tag="div"
                    className="full-page h-screen bg-position-center"
                    fluid={getCoverImageFluid('first_show')}
                  >
                    <div className="content-wrapper absolute inset-y-auto left-0 mr-6 pl-6 flex flex-col h-screen justify-center text-white">
                      <div>
                        <h2>Pierwszy koncert</h2>
                        <h3 className="date">lipiec 2018</h3>
                        <a
                          href="http://babiogorskajesien.pl/galeria-2019"
                          target="_blank"
                          className="btn btn-cta"
                        >
                          Zobacz galerię
                        </a>
                      </div>
                    </div>
                  </BackgroundImage>
                </div>

                <div
                  className="section text-white"
                  data-anchor="ep"
                  data-tooltip="pierwsza EP'ka"
                >
                  <BackgroundImage
                    Tag="div"
                    className="full-page h-screen"
                    fluid={getCoverImageFluid('ep')}
                  >
                    <div className="content-wrapper absolute inset-y-auto left-0 md:left-auto md:right-0 mr-6 pl-6 flex flex-col h-screen justify-center text-white">
                      <div>
                        <h2>
                          Pierwsza EP'ka
                          <br />
                          "Potock"
                        </h2>
                        <h3 className="date">grudzień 2018</h3>
                        <PotockLink
                          to="/sklep/plyty/plyta-potock"
                          className="btn btn-cta"
                        >
                          Kup płytę
                        </PotockLink>
                      </div>
                    </div>
                  </BackgroundImage>
                </div>

                <div
                  className="section text-white"
                  data-anchor="first_clip"
                  data-tooltip="pierwszy klip"
                >
                  <BackgroundImage
                    Tag="div"
                    className="full-page h-screen"
                    fluid={getCoverImageFluid('first_clip')}
                  >
                    <div className="content-wrapper absolute inset-y-auto left-0 mr-6 pl-6 flex flex-col h-screen justify-center text-white">
                      <div>
                        <h2>
                          Pierwszy klip
                          <br />
                          "Ked mi pryszła karta"
                        </h2>
                        <h3 className="date">marzec 2019</h3>
                        <a
                          href="https://www.youtube.com/watch?v=HPSr1DQ7Le4"
                          target="_blank"
                          className="btn btn-cta"
                        >
                          Posłuchaj
                        </a>
                      </div>
                    </div>
                  </BackgroundImage>
                </div>

                <div
                  className="section text-white"
                  data-anchor="first_tour"
                  data-tooltip="pierwsza trasa"
                >
                  <BackgroundImage
                    Tag="div"
                    className="full-page h-screen bg-position-bottom"
                    fluid={getCoverImageFluid('first_tour')}
                  >
                    {/* <div className="content-wrapper absolute inset-y-auto left-0 mr-6 pl-6 flex flex-col h-screen pt-20 text-white"> */}
                    <div className="content-wrapper absolute inset-y-auto left-0 mr-6 pl-6 flex flex-col h-screen justify-center text-white">
                      <div>
                        <h2>Pierwsza trasa koncertowa</h2>
                        <h3 className="date">lato 2019</h3>
                        <div className="places">
                          {tourPlaces.map((place, index) => {
                            return (
                              <div
                                className="text-2xl absolute top-0"
                                key={index}
                              >
                                {place}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </BackgroundImage>
                </div>

                <div
                  className="section text-white"
                  data-anchor="clip_hej_na_ty_babi_gorze"
                  data-tooltip='"Hej na ty Babi Górze"'
                >
                  <BackgroundImage
                    Tag="div"
                    className="full-page h-screen"
                    fluid={getCoverImageFluid('clip_hej_na_ty_babi_gorze')}
                  >
                    <div className="content-wrapper absolute inset-y-auto left-0 mr-6 pl-6 flex flex-col h-screen pt-20 text-white">
                      <div>
                        <h2>
                          Klip promujący debiutancką płytę
                          <br />
                          "Hej na ty Babi Górze"
                        </h2>
                        <h3 className="date">grudzień 2019</h3>
                        <a
                          className="btn btn-cta"
                          href="https://www.youtube.com/watch?v=zUE2qCgw3LI"
                          target="_blank"
                        >
                          Obejrzyj
                        </a>
                      </div>
                    </div>
                  </BackgroundImage>
                </div>

                <div
                  className="section text-white"
                  data-anchor="tvn"
                  data-tooltip="Dzień Dobry TVN"
                >
                  <BackgroundImage
                    Tag="div"
                    className="full-page h-screen"
                    fluid={getCoverImageFluid('tvn')}
                  >
                    <div className="content-wrapper absolute inset-y-auto left-0 mr-6 pl-6 flex flex-col h-screen justify-end pb-20 text-white">
                      <div>
                        <h2>"Dzień Dobry TVN"</h2>
                        <h3 className="date">marzec 2020</h3>
                        <a
                          className="btn btn-cta"
                          href="https://dziendobry.tvn.pl/a/innowacyjne-aranzacje-tradycyjnych-melodii-karpackich-czyli-zespol-potock-z-piosenka-hej-na-babi-gorze"
                          target="_blank"
                        >
                          Obejrzyj
                        </a>
                      </div>
                    </div>
                  </BackgroundImage>
                </div>

                <div
                  className="section text-white"
                  data-anchor="pandemic_clip"
                  data-tooltip="pandemiczny klip"
                >
                  <BackgroundImage
                    Tag="div"
                    className="full-page h-screen bg-position-top"
                    fluid={getCoverImageFluid('pandemic_clip')}
                  >
                    <div className="content-wrapper absolute inset-y-auto left-0 mr-6 pl-6 flex flex-col h-screen justify-center text-white">
                      <div>
                        <h2>
                          Pandemiczny klip
                          <br />
                          "Kieby nos tu było"
                        </h2>
                        <h3 className="date">kwiecień 2020</h3>
                        <a
                          className="btn btn-cta"
                          href="https://www.facebook.com/p0t0ck/videos/301975140770352"
                          target="_blank"
                        >
                          Obejrzyj
                        </a>
                      </div>
                    </div>
                  </BackgroundImage>
                </div>

                <div
                  className="section text-white"
                  data-anchor="premiere_show"
                  data-tooltip="koncert online"
                >
                  <BackgroundImage
                    Tag="div"
                    className="full-page h-screen"
                    fluid={getCoverImageFluid('premiere_show')}
                  >
                    <div className="content-wrapper absolute inset-y-auto left-0 mr-6 pl-6 flex flex-col h-screen justify-center text-white">
                      <div>
                        <h2>
                          Koncert online
                          <br />
                          promujący debiutancką płytę "Z prądem"
                        </h2>
                        <h3 className="date">czerwiec 2020</h3>
                        <a
                          className="btn btn-cta"
                          href="https://www.facebook.com/p0t0ck/videos/288674532284752"
                          target="_blank"
                        >
                          Zobacz jak było
                        </a>
                      </div>
                    </div>
                  </BackgroundImage>
                </div>

                <div
                  className="section text-white"
                  data-anchor="first-album"
                  data-tooltip='płyta "Z prądem"'
                >
                  <BackgroundImage
                    Tag="div"
                    className="full-page h-screen"
                    fluid={getCoverImageFluid('first-album')}
                  >
                    <div className="content-wrapper absolute inset-y-auto right-0 mr-6 pl-6 flex flex-col h-screen justify-center text-white">
                      <div>
                        <h2>
                          Premiera debiutanckiej płyty
                          <br />
                          "Z prądem"
                        </h2>
                        <h3 className="date">czerwiec 2020</h3>
                        <PotockLink
                          className="btn btn-cta"
                          to="/sklep/plyty/plyta-potock-z-pradem"
                        >
                          Kup płytę
                        </PotockLink>
                      </div>
                    </div>
                  </BackgroundImage>
                </div>
              </ReactFullpage.Wrapper>
            </div>
          );
        }}
      />
    </Layout>
  );
}

export default IndexPage;
